
.page-title-area {
  background-color: #f8f9f8;
  position: relative;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 26px;
}

.page-title-content ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.page-title-content ul li {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  color: #221638;
  font-size: 16px;
  font-weight: 600;
}

.page-title-content ul li::before {
  content: "";
  position: absolute;
  right: -10px;
  top: 5px;
  transform: rotate(10deg);
  background-color: #696969;
  height: 15px;
  width: 1px;
}

.page-title-content ul li a {
  color: #606060;
  display: block;
  font-weight: 500;
}

.page-title-content ul li a:hover {
  color: #fe4a55;
}

.page-title-content ul li:last-child {
  margin-right: 0;
}

.page-title-content ul li:last-child::before {
  display: none;
}

.page-title-content h2 {
  text-align: center;
  max-width: 800px;
  margin-top: 80px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 36px;
  font-weight: 800;
}

.page-title-content .rating {
  text-align: center;
  font-size: 17px;
  margin-top: 20px;
}

.page-title-content .rating i {
  color: #dca416;
  display: inline-block;
  margin-right: 3px;
}

.page-title-content .rating .rating-count {
  display: inline-block;
  position: relative;
  top: -2px;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .page-title-area {
    padding-top: 15px;
    padding-bottom: 50px;
  }
  .page-title-content ul li {
    margin-right: 17px;
    font-size: 14px;
    margin-right: 17px;
    margin-bottom: 8px;
  }
  .page-title-content ul li::before {
    right: -11px;
    top: 4px;
    height: 13px;
  }
  .page-title-content h2 {
    max-width: 100%;
    margin-top: 50px;
    font-size: 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-title-content h2 {
    max-width: 720px;
    font-size: 30px;
  }
}
